import { Controller } from "@hotwired/stimulus";
import {TabulatorFull as Tabulator, DownloadModule} from 'tabulator-tables';


export default class extends Controller {

    static targets = ["info", "filterField", "filterType", "filterValue"]

    static values = {items: Array}

    connect() {

        super.connect();
        this.tableLoaded = false


        this.table = new Tabulator(this.infoTarget, {
            autoHeight: true,
            data: this.itemsValue,
            layout: "fitData",
            columns: [
                {title: 'Name', field:"patientLink", formatter:"link", formatterParams:{
                        labelField:"patientName",
                        target:"_blank",
                        rel: "noopener noreferrer"
                    }, download: false},
                {title: 'Workflow', field:"workflowLink", formatter:"link", formatterParams:{
                        labelField:"baseServiceName",
                        target:"_blank",
                        rel: "noopener noreferrer"
                    }, download: false},
                {title: 'Service Class', field: 'baseServiceName', visible: false, download: true},
                {title:"Patient Name", field:"patientName", visible: false, download: true},
                {title:"Patient ID", field:"patientId", visible: false, download: true},
                {title:"Patient Type", field:"patient_type", visible: true, download: true},
                {title:"Conversion", field:"conversionStatus"},
                {title:"ETA", field:"appointmentTimeStr"},
                {title:"Assigned", field:"assignedTo"},
                {title:"Latest Update", field:"comments", width: 500, tooltip:true},
                {title:"Updated On", field:"lastUpdateOn"},
                {title:"Updated By", field:"latestUpdateFrom"},
                {title:"Ref Source", field:"source"},
                {title:"Appt From", field:"appt_from"},
                {title:"City", field:"cityName"},
                {title:"Discharged & Ready", field:"readyForDischarge"},
                {title:"Pricing Agreed", field:"pricingAgreed"},
                {title:"Appointment Status", field:"appointmentStatus", visible: false, download: true},
                {title:"Cancel Responsible", field:"cancelResponsible"},
                {title:"Cancel Reason", field:"cancelReason"},
                {title: 'Ticket', field:"link", formatter:"link", formatterParams:{
                        label:"View",
                        target:"_blank",
                        rel: "noopener noreferrer"
                    }, download: false},
                {title:"Appointment ID", field:"appointmentId", visible: false, download: true},

            ],
        })

        this.table.on("tableBuilt", () => {
            this.tableLoaded = true
        })

    }

    download() {
        this.table.download('csv', 'data.csv')
    }

    confirmedLeadFilter(data) {
        return data.pricingAgreed == 'Yes' && data.readyForDischarge == 'Yes'
    }

    potentialLeadFilter(data) {
        return data.readyForDischarge == 'Yes' && !data.pricingAgreed == 'No'
    }

    clearFilter() {
        this.filterFieldTarget.selectedIndex = 0;
        this.filterTypeTarget.value = "=";
        this.filterValueTarget.value = "";
        this.table.clearFilter();
    }

    applyFilter() {

        let filterField = this.filterFieldTarget.options[this.filterFieldTarget.selectedIndex].value

        if (filterField === 'confirmed') {
            this.table.setFilter(this.confirmedLeadFilter, null)
        } else if (filterField === 'potential') {
            this.table.setFilter(this.potentialLeadFilter, null)
        } else {
            this.table.setFilter(filterField,
                this.filterTypeTarget.options[this.filterTypeTarget.selectedIndex].value,
                this.filterValueTarget.value
            )
        }

    }


}